var root = document.querySelector("#app");

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const FileUploadWithAPI = {
  selectedFile: null,
  uploadStatus: null,
  progress: null,
  previewImg: null,

  reset: function () {
    FileUploadWithAPI.selectedFile = null;
    FileUploadWithAPI.uploadStatus = null;
    FileUploadWithAPI.progress = null;
    FileUploadWithAPI.previewImg = null;
  },

  // API'ye dosya gönderme fonksiyonu
  uploadFile: function () {
    if (!FileUploadWithAPI.selectedFile) {
      FileUploadWithAPI.uploadStatus = "Lütfen bir dosya seçin.";
      return;
    }

    const formData = new FormData();
    formData.append("file", FileUploadWithAPI.selectedFile);

    m.request({
      method: "POST",
      url: "/api/upload", // API URL'inizi buraya ekleyin
      body: formData,
      config: function (xhr) {
        xhr.setRequestHeader("Authorization", "Bearer YOUR_TOKEN"); // Eğer gerekiyorsa

        xhr.upload.addEventListener("progress", function (e) {
          FileUploadWithAPI.progress = (e.loaded / e.total) * 100;

          m.redraw(); // tell Mithril.js that data changed and a re-render is needed
        });
      },
      serialize: function (data) {
        return data; // FormData olduğu için serialize edilmiyor
      },
    })
      .then((response) => {
        FileUploadWithAPI.uploadStatus = "Dosya başarıyla yüklendi!";

        window.location.href = "/i/" + response.filename;
      })
      .catch((error) => {
        FileUploadWithAPI.uploadStatus = "Dosya yüklenirken bir hata oluştu.";
        console.error(error);
      });
  },

  // Bileşenin görünümü
  view: function () {
    return m(
      "div",
      {
        oncreate: () => {
          const dropZone = document.getElementById("dropZone");
          const formFile = document.getElementById("formFile");

          dropZone.addEventListener("click", () => formFile.click());

          dropZone.addEventListener("dragover", (e) => {
            e.preventDefault();
            dropZone.classList.add("bg-light");
          });

          dropZone.addEventListener("dragleave", () => {
            dropZone.classList.remove("bg-light");
          });

          dropZone.addEventListener("drop", (e) => {
            e.preventDefault();
            dropZone.classList.remove("bg-light");
            formFile.files = e.dataTransfer.files;
            updateDropZoneText();
          });

          formFile.addEventListener("change", updateDropZoneText);

          function updateDropZoneText() {
            FileUploadWithAPI.selectedFile = formFile.files[0];

            if (FileUploadWithAPI.selectedFile) {
              getBase64(FileUploadWithAPI.selectedFile).then((data) => {
                FileUploadWithAPI.previewImg = data;

                m.redraw();
              });
            }

            m.redraw();
          }
        },
      },
      m("div", [
        m("div", { class: "mb-3" }, [
          !!FileUploadWithAPI.selectedFile &&
            m(
              "div",
              { class: "alert alert-success", role: "alert" },
              "Ready to upload your image."
            ),
          m(
            "label",
            { class: "form-label", for: "formFile" },
			locale["upload-title"]
          ),
          m(
            "div",
            {
              class: "border border-primary rounded p-4 text-center",
              id: "dropZone",
            },
            locale["upload-dragger"]
          ),
          m("input", {
            class: "d-none",
            type: "file",
            id: "formFile",
            name: "file",
            accept: "image/*",
          }),
        ]),
        !!FileUploadWithAPI.progress &&
          m("div", { class: "mb-3" }, [
            m("label", { class: "form-label" }, "Upload Progress"),
            m("div", { class: "progress" }, [
              m("div", {
                class: "progress-bar",
                role: "progressbar",
                style: { width: FileUploadWithAPI.progress + "%" },
                id: "uploadProgress",
                "aria-valuenow": "0",
                "aria-valuemin": "0",
                "aria-valuemax": "100",
              }),
            ]),
          ]),
        !!FileUploadWithAPI.previewImg &&
          m("div", { class: "mb-3" }, [
            m("label", { class: "form-label" }, "Image Preview"),
            m("div", { class: "text-center" }, [
              m("img", {
                src: FileUploadWithAPI.previewImg,
                class: "img-thumbnail",
                style: { maxWidth: "100%", maxHeight: "300px" },
                alt: "Image Preview",
              }),
            ]),
          ]),
        !!FileUploadWithAPI.previewImg &&
          m("div.d-flex.justify-content-center", [
            m(
              "button",
              {
                class: "btn btn-secondary me-2",
                onclick: FileUploadWithAPI.reset,
              },
              "Cancel"
            ),
            m(
              "button",
              {
                class: "btn btn-primary",
                onclick: FileUploadWithAPI.uploadFile,
                disabled: !FileUploadWithAPI.selectedFile,
              },
              "Upload"
            ),
          ]),
      ])
    );
  },
};

m.mount(root, FileUploadWithAPI);
